import Vue from 'vue'
import BootstrapVue from 'bootstrap-vue'
import router from './router'

import vco from "v-click-outside"
import VueApexCharts from 'vue-apexcharts'
import VueSweetalert2 from 'vue-sweetalert2';
import VueSlideBar from 'vue-slide-bar'
import Vuelidate from 'vuelidate'
import i18n from './i18n'
import store from '@/state/store'
import vBlur from 'v-blur'
import mixin from './mixin.js'
import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.css'

import App from './App.vue'
// As a plugin
import VueMask from 'v-mask'
Vue.config.productionTip = false

import * as VueGoogleMaps from "vue2-google-maps";
import Lightbox from 'vue-easy-lightbox'

Vue.use(Vuetify)
const opts = {
  theme: {
    dark: false, // Set to true for dark mode
  },
}

Vue.use(Lightbox)
Vue.use(VueGoogleMaps, {
  load: {
    key: "AIzaSyAbvyBxmMbFhrzP9Z8moyYr6dCr-pzjhBE",
    libraries: "places"
  },
  installComponents: true
});


// import { initFirebaseBackend } from './helpers/firebase/authUtils';

// import { configureFakeBackend } from './helpers/fakebackend/fake-backend';

// const firebaseConfig = {
//   apiKey: process.env.VUE_APP_APIKEY,
//   authDomain: process.env.VUE_APP_AUTHDOMAIN,
//   databaseURL: process.env.VUE_APP_VUE_APP_DATABASEURL,
//   projectId: process.env.VUE_APP_PROJECTId,
//   storageBucket: process.env.VUE_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.VUE_APP_MESSAGINGSENDERID,
//   appId: process.env.VUE_APP_APPId,
//   measurementId: process.env.VUE_APP_MEASUREMENTID
// };

// if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
//   initFirebaseBackend(firebaseConfig);
// } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
//   configureFakeBackend();
// } 

// window.onbeforeunload = () => {
//     localStorage.clear(); //when broweser closed, clear localstorage
// }

import '@/assets/scss/app.scss'
import Swal from "sweetalert2";
 
Vue.component('VueSlideBar', VueSlideBar)
Vue.use(BootstrapVue)
Vue.use(vco)
Vue.component('apexchart', VueApexCharts)
Vue.use(Vuelidate)
Vue.use(VueSweetalert2);
Vue.use(vBlur)


Vue.use(require("vue-chartist"));
Vue.use(VueMask);

Vue.mixin(mixin);

new Vue({
  router,
  store,
  i18n,
  vuetify: new Vuetify(opts),
  render: h => h(App),
    created() {
        // Check if totalRows is stored in localStorage
        const storedTotalRows = localStorage.getItem('totalRows');

        // Set the initial value of totalRows (use a default if not in storage)
        this.$store.commit('setTotalRows', storedTotalRows ? parseInt(storedTotalRows) : 0 );
    },

}).$mount('#app')
