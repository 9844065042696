import Vue from 'vue'
import VueRouter from 'vue-router'

import VueMeta from 'vue-meta'
import store from '@/state/store'

import routes from './routes'
import Auth from '@/services/Auth'
import Swal from "sweetalert2";

Vue.use(VueRouter)
Vue.use(VueMeta, {
    // The component option name that vue-meta looks for meta info on.
    keyName: 'page',
})

const router = new VueRouter({
    mode: 'history',
    routes,
    // Use the HTML5 history API (i.e. normal-looking routes)
    // instead of routes with hashes (e.g. example.com/#/about).
    // This may require some server configuration in production:
    // https://router.vuejs.org/en/essentials/history-mode.html#example-server-configurations
    
    scrollBehavior(to, from, savedPosition) {
        if (savedPosition) {
            return savedPosition
        } else {
            return {
                x: 0,
                y: 0
            }
        }
    },
})

async function getToken() {

    try {
        return Auth.validate();

    }catch(err) {

        console.log('Ohh no:', err.message);
    }
    /* const data = await Auth.validate()
         .then(response => {
             loggeduser = response.data.token;
             console.log(loggeduser);
         }).catch(error => {
             loggeduser = error.response.data;
             console.log(loggeduser);
         })*/
}

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
    if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
        // Check if auth is required on this route
        // (including nested routes).
        const authRequired = routeTo.matched.some((route) => route.meta.authRequired)

        // If auth isn't required for the route, just continue.
        if (!authRequired) return next()

        // If auth is required and the user is logged in...
        if (store.getters['auth/loggedIn']) {
            // Validate the local user token...
            return store.dispatch('auth/validate').then((validUser) => {
                // Then continue if the token still represents a valid user,
                // otherwise redirect to login.
                validUser ? next() : redirectToLogin()
            })
        }

        // If auth is required and the user is NOT currently logged in,
        // redirect to login.
        redirectToLogin()

        // eslint-disable-next-line no-unused-vars
        // eslint-disable-next-line no-inner-declarations
        function redirectToLogin() {
            // Pass the original route to the login component
            next({
                name: 'login',
                query: {
                    redirectFrom: routeTo.fullPath
                }
            })
        }
    } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
        const publicPages = ['/login', '/register', '/forgot-password'];
        const authpage = !publicPages.includes(routeTo.path);
        const loggeduser = localStorage.getItem('user');

        if (authpage && !loggeduser) {
            return next('/login');
        }

        next();
    } else if (process.env.VUE_APP_DEFAULT_AUTH === "api") {

       /* const authRequired = routeTo.matched.some((route) => route.meta.authRequired)
        if (!authRequired) return next()*/

         //(VP) custom for public routes 
        const publicPages = ['/login', '/register', '/forgot-password', '/logout'];
        const authpage = !publicPages.includes(routeTo.path);
        var loggeduser = typeof localStorage.getItem('access_token') !== 'undefined' ? localStorage.getItem('access_token') : ''

        if(authpage){
            if(loggeduser == null){
                window.location.href = '/login'
            }else{

                let userToken = Auth.validate()

                userToken.then(data => {
                    if(data.token == 'valid'){
                        //(VP) custom rule  - access by tenant
                        const accessSideMenu = localStorage.getItem("access_objects");
                        const accessSideMenu_arr = accessSideMenu.split(',');
                        const routeAccess = typeof(routeTo.meta.access) != 'undefined' ? routeTo.meta.access : ''
                        const ObjectPermission = typeof(routeTo.meta.accessObject) != 'undefined' ? routeTo.meta.accessObject : routeTo.path

                        Auth.hasObjectPermission({
                            object: ObjectPermission
                        }).then((response) => {
                            if(routeAccess !='' && accessSideMenu_arr.includes(routeAccess)){
                                next();
                            }
                        })
                        .catch((error) => { 
                            console.log(error);
                            const errorMessage = (typeof error.response.data.error != 'undefined')  ? error.response.data.error : '';
                            const error_code  =  (typeof error.response.data.error_code != 'undefined') ? error.response.data.error_code : '';

                            if(error_code==101 || errorMessage=='Invalid or missing CSRF token !'){
                                //CSRF Token Expiration
                                localStorage.clear();
                                Swal.fire({
                                    position: "top",
                                    icon: "warning",
                                    title: "CSRF Session Expired ! Please LogIn again!",
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Log In',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result.isConfirmed) {
                                        window.location.href = '/login'
                                    }
                                })
                            } else {
                                Swal.fire({
                                    position: "top",
                                    icon: "warning",
                                    title: "Unauthorized !",
                                    confirmButtonColor: '#3085d6',
                                    confirmButtonText: 'Back',
                                    allowOutsideClick: false
                                }).then((result) => {
                                    if (result) {
                                        next(routeFrom.fullPath);
                                    }
                                })
                            }

                            
                        })

                    }else{
                        next('/login');
                    }

                }).catch(err => {
                    localStorage.clear()
                    next('/login');
                });
            }
        }else{
            if(loggeduser && routeTo.path=='/login'){
                next('/');
            }

            if (authpage && !loggeduser) {
                next('/login');
            }

            return next();
        }
    }
})

router.beforeResolve(async (routeTo, routeFrom, next) => {
    // Create a `beforeResolve` hook, which fires whenever
    // `beforeRouteEnter` and `beforeRouteUpdate` would. This
    // allows us to ensure data is fetched even when params change,
    // but the resolved route does not. We put it in `meta` to
    // indicate that it's a hook we created, rather than part of
    // Vue Router (yet?).
    try {
        // For each matched route...
        for (const route of routeTo.matched) {
            await new Promise((resolve, reject) => {
                // If a `beforeResolve` hook is defined, call it with
                // the same arguments as the `beforeEnter` hook.
                if (route.meta && route.meta.beforeResolve) {
                    route.meta.beforeResolve(routeTo, routeFrom, (...args) => {
                        // If the user chose to redirect...
                        if (args.length) {
                            // If redirecting to the same route we're coming from...
                            // Complete the redirect.
                            next(...args)
                            reject(new Error('Redirected'))
                        } else {
                            resolve()
                        }
                    })
                } else {
                    // Otherwise, continue resolving the route.
                    resolve()
                }
            })
        }
        // If a `beforeResolve` hook chose to redirect, just return.
    } catch (error) {
        return
    }

    // If we reach this point, continue resolving the route.
    next()
})

export default router