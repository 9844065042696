import store from "@/state/store";

let menuObjects;

var home_page = 'index';
if(typeof process.env.VUE_APP_HOMEPAGE !== 'undefined' ){
   home_page = process.env.VUE_APP_HOMEPAGE;
}

menuObjects = [
    {
        path: "/",
        name: "home",
        meta: {
            authRequired: true,
            access: "dashboard",
            accessObject: "/",
        },
        component: () => import("../views/pages/dashboard/"+home_page),
    }
]


// if(process.env.VUE_APP_TENANT === 'MON')
// {
//   menuObjects = [
//     {
//       path: "/",
//       name: "home",
//       meta: {
//         authRequired: true,
//         access: "dashboard",
//         accessObject: "/",
//       },
//       component: () => import("../views/pages/dashboard/monitoring"),
//     }
//   ]
// }else{
//   menuObjects = [
//     {
//       path: "/",
//       name: "home",
//       meta: {
//         authRequired: true,
//         access: "dashboard",
//         accessObject: "/",
//       },
//       component: () => import("../views/pages/dashboard/index"),
//     }
//   ]
// }

export default [
  ...menuObjects,
  {
    path: "/login",
    name: "login",
    component: () => import("../views/pages/account/login"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authapi/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
      accessObject: "/login",
    },
  },
  {
    path: "/forgot-password",
    name: "Forgot-password",
    component: () => import("../views/pages/account/forgot-password"),
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        // If the user is already logged in
        if (store.getters["authapi/loggedIn"]) {
          // Redirect to the home page instead
          next({
            name: "home",
          });
        } else {
          // Continue to the login page
          next();
        }
      },
      accessObject: "/forgot-password",
    },
  },
  {
    path: "/logout",
    name: "logout",
    meta: {
      beforeResolve(routeTo, routeFrom, next) {
        if (process.env.VUE_APP_DEFAULT_AUTH === "firebase") {
          store.dispatch("auth/logOut");
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "fakebackend") {
          store.dispatch("authfack/logout");
        } else if (process.env.VUE_APP_DEFAULT_AUTH === "api") {
          store.dispatch("authapi/logout");
        }
        const authRequiredOnPreviousRoute = routeFrom.matched.some((route) =>
          route.push("/login")
        );
        // Navigate back to previous page, or home as a fallback
        next(
          authRequiredOnPreviousRoute
            ? {
                name: "home",
              }
            : {
                ...routeFrom,
              }
        );
      },
      accessObject: "/logout",
    },
  },
  {
    path: "/customers",
    name: "customers",
    meta: {
      authRequired: true,
      access: "customers",
      accessObject: "/customers",
    },
    component: () => import("../views/pages/customers/index"),
  },
  {
    path: "/customers/profile/:id",
    name: "customers_profile",
    meta: {
      authRequired: true,
      access: "customers",
      accessObject: "/customers/profile/:id",
    },
    component: () => import("../views/pages/customers/profile"),
    props: true,
  },
  {
    path: "/customers/group_details/:group_id",
    name: "customers_group_details",
    meta: {
      authRequired: true,
      access: "customers",
      accessObject: "/customers/group_details/:group_id",
    },
    component: () => import("../views/pages/customers/group_details"),
    props: true,
  },
  {
    path: "/customers/requests",
    name: "customers_requests",
    meta: {
      authRequired: true,
      access: "customers",
      accessObject: "/customers/requests",
    },
    component: () => import("../views/pages/customers/customers_requests"),
    props: true,
  },
  {
     path: "/customers/optins",
     name: "optins",
     meta: {
       authRequired: true,
       access: "customers",
       accessObject: "/customers/optins",
     },
     component: () => import("../views/pages/customers/optins"),
     props: true,
  },
  {
    path: "/customers/groups",
    name: "groups",
    meta: {
      authRequired: true,
      access: "customers",
      accessObject: "/customers/groups",
    },
    component: () => import("../views/pages/customers/groups"),
    props: true,
  },
  {
    path: "/teams/list",
    name: "teams_list",
    meta: {
      authRequired: true,
      access: "teams",
      accessObject: "/teams/list",
    },
    component: () => import("../views/pages/teams/list"),
    props: true,
  },
  {
    path: "/teams/squad",
    name: "teams_squad",
    meta: {
      authRequired: true,
      access: "teams",
      accessObject: "/teams/squad",
    },
    component: () => import("../views/pages/teams/squad"),
    props: true,
  },
  {
    path: "/teams/list_sport",
    name: "list_sport",
    meta: {
      authRequired: true,
      access: "teams",
      accessObject: "/teams/list_sport",
    },
    component: () => import("../views/pages/teams/list_sport"),
    props: true,
  },
  {
    path: "/fixtures/featured_matches",
    name: "fixtures_featured_matches",
    meta: {
      authRequired: true,
      access: "fixtures",
      accessObject: "/fixtures/featured_matches",
    },
    component: () => import("../views/pages/fixtures/featured_matches"),
    props: true,
  },
  {
    path: "/fixtures/rounds",
    name: "fixtures_rounds",
    meta: {
      authRequired: true,
      access: "fixtures",
      accessObject: "/fixtures/rounds",
    },
    component: () => import("../views/pages/fixtures/rounds"),
    props: true,
  },
  {
    path: "/fixtures/standings",
    name: "fixtures_standings",
    meta: {
      authRequired: true,
      access: "fixtures",
      accessObject: "/fixtures/standings",
    },
    component: () => import("../views/pages/fixtures/standings"),
    props: true,
  },
  {
    path: "/games/leaderboard",
    name: "leaderboard",
    meta: {
      authRequired: true,
      access: "games",
      accessObject: "/games/leaderboard",
    },
    component: () => import("../views/pages/games/leaderboard"),
    props: true,
  },
  {
    path: "/games/mini_games",
    name: "mini_games",
    meta: {
      authRequired: true,
      access: "games",
      accessObject: "/games/mini_games",
    },
    component: () => import("../views/pages/games/mini_games"),
    props: true,
  },
  {
    path: "/advertising/banner",
    name: "advertising_banner",
    meta: {
      authRequired: true,
      access: "advertising",
      accessObject: "/advertising/banner",
    },
    component: () => import("../views/pages/advertising/banner"),
    props: true,
  },
  {
    path: "/advertising/advertorial",
    name: "advertising_advertorial",
    meta: {
      authRequired: true,
      access: "advertising",
      accessObject: "/advertising/advertorial",
    },
    component: () => import("../views/pages/advertising/advertorial"),
    props: true,
  },
  {
    path: "/advertising/sponsors",
    name: "advertising_sponsors",
    meta: {
      authRequired: true,
      access: "advertising",
      accessObject: "/advertising/sponsors",
    },
    component: () => import("../views/pages/advertising/sponsors"),
    props: true,
  },
  {
    path: "/advertising/sponsor/:id",
    name: "advertising_sponsor",
    meta: {
      authRequired: true,
      access: "advertising",
      accessObject: "/advertising/sponsor/:id",
    },
    component: () => import("../views/pages/advertising/sponsor"),
    props: true,
  },
  {
    path: "/advertising/player",
    name: "advertising_player",
    meta: {
      authRequired: true,
      access: "advertising",
      accessObject: "/advertising/player",
    },
    component: () => import("../views/pages/advertising/player"),
    props: true,
  },
  {
    path: "/advertising/advertisements",
    name: "advertisements",
    meta: {
      authRequired: true,
      access: "advertising",
      accessObject: "/advertising/advertisements",
    },
    component: () => import("../views/pages/advertising/advertisements"),
    props: true,
  },
  {
    path: "/advertising/deals",
    name: "Deals",
    meta: {
        authRequired: false,
        access: "advertising",
        accessObject: "/advertising/deals",
    },
    component: () => import("../views/pages/advertising/deals"),
    props: true,
    },
    {
      path: "/advertising/app_ads",
      name: "advertising_app_ads",
      meta: {
        authRequired: true,
        access: "advertising",
        accessObject: "/advertising/app_ads",
      },
      component: () => import("../views/pages/advertising/app_ads"),
      props: true,
    },
  {
    path: "/news/feed",
    name: "rss_news",
    meta: {
      authRequired: true,
      access: "news",
      accessObject: "/news/feed",
    },
    component: () => import("../views/pages/news/feed"),
    props: true,
  },
  {
    path: "/news/videos",
    name: "rss_videos",
    meta: {
      authRequired: true,
      access: "news",
      accessObject: "/news/videos",
    },
    component: () => import("../views/pages/news/videos"),
    props: true,
  },
  {
    path: "/news/shorts",
    name: "shosts",
    meta: {
       authRequired: true,
       access: "shorts",
       accessObject: "/news/shorts",
    },
    component: () => import("../views/pages/news/shorts"),
    props: true,
    },
  {
    path: "/games/man_of_the_match",
    name: "man_of_the_match",
    meta: {
      authRequired: true,
      access: "games",
      accessObject: "/games/man_of_the_match",
    },
    component: () => import("../views/pages/games/man_of_the_match"),
    props: true,
  },
  {
    path: "/merchandising/products",
    name: "merchandising_products",
    meta: {
      authRequired: true,
      access: "merchandising",
      accessObject: "/merchandising/products",
    },
    component: () => import("../views/pages/merchandising/products"),
    props: true,
  },
  {
    path: "/merchandising/product_details/:id",
    name: "product_details",
    meta: {
      authRequired: true,
      access: "merchandising",
      accessObject: "/merchandising/product_details/:id",
    },
    component: () => import("../views/pages/merchandising/product_details"),
    props: true,
  },
  {
      path: "/merchandising/media",
      name: "merchandising_media",
      meta: {
          authRequired: true,
          access: "merchandising",
          accessObject: "/merchandising/media",
      },
      component: () => import("../views/pages/merchandising/media"),
      props: true,
  },
  {
      path: "/merchandising/promotions",
      name: "merchandising_promotions",
      meta: {
          authRequired: true,
          access: "merchandising",
          accessObject: "/merchandising/promotions",
      },
      component: () => import("../views/pages/merchandising/promotions"),
      props: true,
  },
  {
    path: "/games/guess_the_score",
    name: "guess_the_score",
    meta: {
      authRequired: true,
      access: "games",
      accessObject: "/games/guess_the_score",
    },
    component: () => import("../views/pages/games/guess_the_score"),
    props: true,
  },
  {
    path: "*",
    name: "not_found",
    meta: {
      authRequired: true,
      accessObject: "/not_found",
    },
    component: () => import("../views/pages/utility/404"),
  },
  {
    path: "/notifications",
    name: "notif",
    meta: {
      authRequired: true,
      access: "notifications",
      accessObject: "/notifications",
    },
    component: () => import("../views/pages/notifications/index"),
  },
  {
    path: "/notifications/details",
    name: "notif_details",
    meta: {
      authRequired: true,
      access: "notifications",
      accessObject: "/notifications/details",
    },
    component: () => import("../views/pages/notifications/details"),
  },
  {
    path: "/monitor/queues",
    name: "monitor_queues",
    meta: {
      authRequired: true,
      access: "queues",
      accessObject: "/monitor/queues",
    },
    component: () => import("../views/pages/monitor/queues"),
  },
  {
     path: "/monitor/rbac/dashboard_access",
     name: "dashboard_access",
     meta: {
       authRequired: true,
       access: "rbac",
       accessObject: "/monitor/rbac/dashboard_access",
     },
     component: () => import("../views/pages/monitor/dashboard_access"),
    },
    {
        path: "/monitor/rbac/api_access",
        name: "api_access",
        meta: {
            authRequired: true,
            access: "rbac",
            accessObject: "/monitor/rbac/api_access",
        },
        component: () => import("../views/pages/monitor/api_access"),
    },
    {
        path: "/monitor/rbac/rbac_access",
        name: "rbac_access",
        meta: {
            authRequired: true,
            access: "rbac",
            accessObject: "/monitor/rbac/rbac_access",
        },
        component: () => import("../views/pages/monitor/rbac_access"),
    },
  {
    path: "/monitor/queues/:id",
    name: "monitor_queue_detail",
    meta: {
      authRequired: true,
      access: "monitor",
      accessObject: "/monitor/queues/:id",
    },
    component: () => import("../views/pages/monitor/queue_details"),
    props: true,
  },
  {
     path: "/monitor/terms_and_conditions",
     name: "terms_and_conditions",
     meta: {
       authRequired: true,
       access: "terms_and_conditions",
       accessObject: "/monitor/terms_and_conditions",
     },
    component: () => import("../views/pages/monitor/terms_and_conditions"),
    },
    {
      path: "/monitor/logs/api_errors",
      name: "api_errors",
      meta: {
        authRequired: true,
        access: "logs",
        accessObject: "/monitor/logs",
      },
      component: () => import("../views/pages/monitor/logs/api_errors"),
    },
    {
      path: "/monitor/logs/api_cron_logs",
      name: "api_cron_logs",
      meta: {
        authRequired: true,
        access: "logs",
        accessObject: "/monitor/logs/api_cron_logs",
      },
      component: () => import("../views/pages/monitor/logs/api_cron_logs"),
    },
    {
      path: "/monitor/logs/api_exceptions",
      name: "api_exceptions",
      meta: {
        authRequired: true,
        access: "logs",
        accessObject: "/monitor/logs/api_exceptions",
      },
      component: () => import("../views/pages/monitor/logs/api_exceptions"),
    },
    {
      path: "/monitor/logs/api_alerts",
      name: "api_alerts",
      meta: {
        authRequired: true,
        access: "logs",
        accessObject: "/monitor/logs/api_alerts",
      },
      component: () => import("../views/pages/monitor/logs/api_alerts"),
    },
    {
    path: "/application/access",
    name: "application_access",
    meta: {
      authRequired: true,
      access: "application",
      accessObject: "/application/access",
    },
    component: () => import("../views/pages/application/access"),
  },
  {
    path: "/application/params",
    name: "application_params",
    meta: {
      authRequired: true,
      access: "application",
      accessObject: "/application/params",
    },
    component: () => import("../views/pages/application/app_params_keys"),
  },
  {
    path: "/orders",
    name: "Orders",
    meta: {
      authRequired: true,
      access: "orders",
      accessObject: "/orders",
    },
    component: () => import("../views/pages/orders/index"),
  },
  // {
  //   path: "/merchandising/products",
  //   name: "merchandising_products",
  //   meta: {
  //     authRequired: true,
  //     access: "merchandising",
  //   },
  //   component: () => import("../views/pages/merchandising/products"),
  //   props: true,
  // },
  // {
  //   path: "/merchandising/products/:id",
  //   name: "merchandising_product",
  //   meta: {
  //     authRequired: true,
  //     access: "merchandising",
  //   },
  //   component: () => import("../views/pages/merchandising/product_details"),
  //   props: true,
  // },
  {
    path: "/financials",
    name: "Transactions",
    meta: {
      authRequired: true,
      access: "financials",
      accessObject: "/financials",
    },
    component: () => import("../views/pages/financials/index"),
  },
  {
      path: "/financials/stripe_payouts",
      name: "Stripe Payouts",
      meta: {
         authRequired: true,
         access: "financials",
         accessObject: "/financials/stripe_payouts",
      },
     component: () => import("../views/pages/financials/stripe_payouts"),
  },
  {
    path: "/cashless",
    name: "Cards management",
    meta: {
      authRequired: true,
      access: "cashless",
      accessObject: "/cashless",
    },
    component: () => import("../views/pages/cashless/index"),
  },
  {
    path: "/topup",
    name: "Topup",
    meta: {
      authRequired: true,
      access: "cashless",
      accessObject: "/topup",
    },
    component: () => import("../views/pages/cashless/topup"),
  },
  {
    path: "/profile",
    name: "User Profile management",
    meta: {
      authRequired: true,
      access: "profile",
      accessObject: "/profile",
    },
    component: () => import("../views/pages/account/profile"),
  },
  {
    path: "/users",
    name: "Users management",
    meta: {
      authRequired: true,
      access: "users",
      accessObject: "/users",
    },
    component: () => import("../views/pages/account/users"),
  },
  {
    path: "/cashless/card-details/:id",
    name: "Card details",
    meta: {
      authRequired: true,
      access: "cashless",
      accessObject: "/cashless/card-details/:id",
    },
    component: () => import("../views/pages/cashless/card-details"),
  },
  {
    path: "/ticketing/season_tickets",
    name: "Ticketing",
    meta: {
      authRequired: true,
      access: "ticketing",
      accessObject: "/ticketing/season_tickets",
    },
    component: () => import("../views/pages/ticketing/season_tickets"),
  },
  {
      path: "/ticketing/match_tickets",
      name: "Match Tickets",
      meta: {
          authRequired: true,
          access: "ticketing",
          accessObject: "/ticketing/match_tickets",
      },
      component: () => import("../views/pages/ticketing/match_tickets"),
  },
  {
    path: "/ticketing/tickets_matches",
    name: "Ticketing Matches",
    meta: {
      authRequired: true,
      access: "ticketing",
      accessObject: "/ticketing/tickets_matches",
    },
    component: () => import("../views/pages/ticketing/tickets_matches.vue"),
    },
  {
      path: "/ticketing/axess_setup",
      name: "Axess Setup",
      meta: {
          authRequired: true,
          access: "ticketing",
          accessObject: "/ticketing/axess_setup",
      },
      component: () => import("../views/pages/ticketing/axess_setup.vue"),
  },
  {
    path: "/ticketing/tickets_matches/tickets_match_details",
    name: "Ticketing Match Details ",
    meta: {
      authRequired: true,
      access: "ticketing",
      accessObject: "/ticketing/tickets_matches/tickets_match_details",
    },
    component: () => import("../views/pages/ticketing/tickets_match_details.vue"),
    props: true,
  },
  {
      path: "/ticketing/season_products",
      name: "Season Setup",
      meta: {
       authRequired: false,
       access: "ticketing",
       accessObject: "/ticketing/season_products",
      },
      component: () => import("../views/pages/ticketing/season_products"),
      props: true,
  },
  {
    path: "/fixtures/matches",
    name: "matches",
    meta: {
      authRequired: true,
      access: "fixtures",
      accessObject: "/fixtures/matches",
    },
    component: () => import("../views/pages/fixtures/matches.vue"),
  },
  {
    path: "/fixtures/matches_new",
    name: "matches_new",
    meta: {
      authRequired: true,
      access: "fixtures",
      accessObject: "/fixtures/matches_new",
    },
    component: () => import("../views/pages/fixtures/matches_new.vue"),
  },
  {
    path: "/fixtures/matches_new/:id",
    name: "match_new",
    meta: {
      authRequired: true,
      access: "fixtures",
      accessObject: "/fixtures/matches_new/:id",
    },
    component: () => import("../views/pages/fixtures/match_simulator_new.vue"),
  },
  {
    path: "/fixtures/matches/:id",
    name: "match",
    meta: {
      authRequired: true,
      access: "fixtures",
      accessObject: "/fixtures/matches/:id",
    },
    component: () => import("../views/pages/fixtures/match_simulator.vue"),
  },
  {
      path: "/fixtures/competition",
      name:"Competition Fixtures",
      meta: {
          authRequired:true,
          access: "fixtures",
          accessObject:"/fixtures/competition"
      },
      component: () => import("../views/pages/fixtures/competition_fixtures.vue")
  },
  {
      path: "/fixtures/current",
      name: "Fixture",
      meta: {
          authRequired:true,
          access: "fixtures",
          accessObject:"/fixtures/current"
      },
      component: () => import("../views/pages/fixtures/fixturesList.vue")
  },
  {
      path: "/fixtures/current/:id",
      name: "Fixtures Details",
      meta: {
          authRequired:false,
          access: "fixtures",
          accessObject:"/fixtures/current/:id"
      },
      component: () => import("../views/pages/fixtures/fixture.vue")
  },
  {
    path: "/app/popup",
    name: "Marketing Popup",
    meta: {
      authRequired: true,
      access: "app",
      accessObject: "/app/popup",
    },
    component: () => import("../views/pages/app/popup.vue"),
  },
    {
        path: "/app/app_params",
        name: "Application Params",
        meta: {
            authRequired: true,
            access: "app",
            accessObject: "/app/app_params",
        },
        component: () => import("../views/pages/app/app_params.vue"),
    },
    {
        path: "/app/app_crm_mimic",
        name: "Application Crm Mimic",
        meta: {
            authRequired: true,
            access: "app",
            accessObject: "/app/app_crm_mimic",
        },
        component: () => import("../views/pages/app/app_crm_mimic.vue"),
    },
    {
      path: "/app/settings",
      name: "Settings",
      meta: {
        authRequired: true,
        access: "app",
        accessObject: "/app/settings",
      },
      component: () => import("../views/pages/app/settings.vue"),
    },
    {
        path: "/media/media_management",
        name: "Media Management",
        meta: {
            authRequired: true,
            access: "media",
            accessObject: "/media/media_management",
        },
        component: () => import("../views/pages/media/media_management.vue"),
    },
  {
    path: "/social/social_levels",
    name: "Social Levels",
    meta: {
      authRequired: true,
      access: "social",
      accessObject: "/social/social_levels",
    },
    component: () => import("../views/pages/social/social_levels.vue"),
  },
  {
    path: "/social/social_milestone",
    name: "Social Milestone",
    meta: {
      authRequired: true,
      access: "social",
      accessObject: "/social/social_milestone",
    },
    component: () => import("../views/pages/social/social_milestone.vue"),
  },
  {
    path: "/social/social_xp",
    name: "Social xp",
    meta: {
      authRequired: true,
      access: "social",
      accessObject: "/social/social_xp",
    },
    component: () => import("../views/pages/social/social_xp.vue"),
  },

  /*
  {
    path: "/games/matches",
    name: "matches",
    meta: {
      authRequired: true,
      access: "games",
      accessObject: "/games/matches",
    },
    component: () => import("../views/pages/games/matches.vue"),
  },
  {
    path: "/games/matches/:id",
    name: "match",
    meta: {
      authRequired: true,
      access: "games",
      accessObject:"/games/matches/:id",
    },
    component: () => import("../views/pages/games/match_simulator.vue"),
  },
  */




/*
  template routes
  {
    path: "/calendar",
    name: "calendar",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/calendar/index"),
  },
  {
    path: "/chat",
    name: "chat",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/chat/index"),
  },
  {
    path: "/ecommerce/products",
    name: "Products",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/products"),
  },
  {
    path: "/ecommerce/product-detail/:id",
    name: "Product Detail",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/product-detail"),
  },
  {
    path: "/ecommerce/orders",
    name: "Ecomerce Orders",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/orders"),
  },
  {
    path: "/ecommerce/customers",
    name: "Customers",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/customers"),
  },
  {
    path: "/ecommerce/cart",
    name: "Cart",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/cart"),
  },
  {
    path: "/ecommerce/checkout",
    name: "Checkout",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/checkout"),
  },
  {
    path: "/ecommerce/shops",
    name: "Shops",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/shops"),
  },
  {
    path: "/ecommerce/add-product",
    name: "Add Product",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ecommerce/add-product"),
  },
  {
    path: "/email/inbox",
    name: "Inbox",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/email/inbox"),
  },
  {
    path: "/email/reademail/:id",
    name: "Read Email",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/email/reademail"),
  },
  {
    path: "/invoices/detail",
    name: "Invoice Detail",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/invoices/detail"),
  },
  {
    path: "/invoices/list",
    name: "Invoice List",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/invoices/list"),
  },
  {
    path: "/contacts/grid",
    name: "User Grid",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/contacts/grid"),
  },
  {
    path: "/contacts/profile",
    name: "Profile",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/contacts/profile"),
  },
  {
    path: "/utility/starter",
    name: "Starter-page",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/starter"),
  },
  {
    path: "/utility/maintenance",
    name: "maintenance",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/maintenance"),
  },
  {
    path: "/utility/comingsoon",
    name: "comingsoon",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/comingsoon"),
  },
  {
    path: "/utility/timeline",
    name: "timeline",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/timeline"),
  },
  {
    path: "/utility/faqs",
    name: "faqs",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/faqs"),
  },
  {
    path: "/utility/pricing",
    name: "pricing",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/pricing"),
  },
  {
    path: "/utility/404",
    name: "error-404",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/404"),
  },
  {
    path: "/utility/500",
    name: "error-500",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/utility/500"),
  },
  {
    path: "/form/elements",
    name: "elements",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/elements"),
  },
  {
    path: "/form/validation",
    name: "validation",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/validation"),
  },
  {
    path: "/form/advanced",
    name: "form-advanced",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/advanced"),
  },
  {
    path: "/form/editor",
    name: "editor",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/editor"),
  },
  {
    path: "/form/upload",
    name: "upload",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/upload"),
  },
  {
    path: "/form/repeater",
    name: "repeater",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/repeater"),
  },
  {
    path: "/form/wizard",
    name: "wizard",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/wizard"),
  },
  {
    path: "/form/mask",
    name: "mask",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/form/mask"),
  },
  {
    path: "/tables/basic",
    name: "basic-table",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/tables/basic"),
  },
  {
    path: "/tables/advanced",
    name: "advanced",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/tables/advanced"),
  },
  {
    path: "/charts/apex",
    name: "apex",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/charts/apex/index"),
  },
  {
    path: "/charts/chartist",
    name: "chartist",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/charts/chartist/index"),
  },
  {
    path: "/charts/chartjs",
    name: "chartjs",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/charts/chartjs/index"),
  },
  {
    path: "/charts/echart",
    name: "echart",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/charts/echart/index"),
  },
  {
    path: "/icons/unicons",
    name: "unicons",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/icons/unicons"),
  },
  {
    path: "/icons/boxicons",
    name: "boxicons",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/icons/boxicons"),
  },
  {
    path: "/icons/materialdesign",
    name: "materialdesign",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/icons/materialdesign"),
  },
  {
    path: "/icons/dripicons",
    name: "dripicons",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/icons/dripicons"),
  },
  {
    path: "/icons/fontawesome",
    name: "fontawesome",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/icons/fontawesome"),
  },
  {
    path: "/maps/google",
    name: "google",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/maps/google"),
  },
  {
    path: "/maps/leaflet",
    name: "leaflet",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/maps/leaflet"),
  },
  {
    path: "/ui/alerts",
    name: "alerts",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/alerts"),
  },
  {
    path: "/ui/buttons",
    name: "buttons",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/buttons"),
  },
  {
    path: "/ui/cards",
    name: "cards",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/cards"),
  },
  {
    path: "/ui/carousel",
    name: "carousel",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/carousel"),
  },
  {
    path: "/ui/dropdown",
    name: "dropdown",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/dropdown"),
  },
  {
    path: "/ui/grid",
    name: "grid",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/grid"),
  },
  {
    path: "/ui/images",
    name: "images",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/images"),
  },
  {
    path: "/ui/lightbox",
    name: "lightbox",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/lightbox"),
  },
  {
    path: "/ui/modals",
    name: "modals",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/modals"),
  },
  {
    path: "/ui/rangeslider",
    name: "rangeslider",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/rangeslider"),
  },
  {
    path: "/ui/progressbar",
    name: "progressbar",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/progressbar"),
  },
  {
    path: "/ui/placeholder",
    name: "placeholder",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/placeholder"),
  },
  {
    path: "/ui/sweet-alert",
    name: "sweet-alert",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/sweet-alert"),
  },

  {
    path: "/ui/tabs-accordions",
    name: "tabs-accordions",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/tabs-accordions"),
  },
  {
    path: "/ui/typography",
    name: "typography",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/typography"),
  },

  {
    path: "/ui/video",
    name: "video",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/video"),
  },
  {
    path: "/ui/general",
    name: "general",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/general"),
  },
  {
    path: "/ui/colors",
    name: "colors",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/colors"),
  },
  {
    path: "/ui/rating",
    name: "rating",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/ui/rating"),
  },
  {
    path: "/auth/login-1",
    name: "login-1",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/auth/login-1"),
  },
  {
    path: "/auth/register-1",
    name: "register-1",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/auth/register-1"),
  },
  {
    path: "/auth/lock-screen",
    name: "lock-screen",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/auth/lock-screen"),
  },
  {
    path: "/auth/recoverpwd",
    name: "recoverpwd",
    meta: {
      authRequired: true,
      access: "template",
    },
    component: () => import("../views/pages/auth/recoverpwd"),
  },
  
  
  */
];
